import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Back Squat 2-2-2-2\\@90%1RM`}</p>
    <p>{`DB Stiff Leg Deadlifts 4-4-4-4`}</p>
    <p>{`then,`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`5/leg Pistols`}</p>
    <p>{`10-Goblet Squats (53/35)`}</p>
    <p>{`15-KBS’s (53/35)`}</p>
    <p>{`rest 3:00 then,`}</p>
    <p>{`50 Back Squats for time (185/125). `}{`*`}{`EMOM 5 burpees.`}</p>
    <p>{`*`}{`Record 3 round time and 50 back squat/burpee separately`}{`*`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Starting next week we will no longer have a 6:30pm class on Tuesday
& Thursday at East due to low attendance.  `}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      